import React from 'react';
import classnames from 'classnames';
import heroStyles from './hero.module.scss';
import Section from '../section';
import { ButtonLink, ButtonGroup } from '../button';
import { heroPropType } from '../../prop-types/';

/* Allows different classNames to be added to certain elements
 depending on the value of the 'imageBottom' boolean - 
 to position images correctly according to the designs */
const getElementAlignmentModifierClass = (baseClass, isHomepageHero) =>
  isHomepageHero ? heroStyles[`${baseClass}HomepageHero`] : heroStyles[`${baseClass}OtherHero`];

const Hero = ({
  node: {
    backgroundColor,
    headline,
    headlineTextColor,
    teaser,
    teaserColor,
    heroButton,
    heroImage,
    heroImageAltText,
    isHomepageHero,
  },
}) => (
  <Section type="sidePaddingOnly" backgroundColor={backgroundColor}>
    <div className={heroStyles.wrapper}>
      <div className={heroStyles.containerItems}>
        <h1 className={heroStyles.headline} style={{ color: headlineTextColor }}>
          {headline}
        </h1>
        <p
          className={classnames(
            heroStyles.teaser,
            getElementAlignmentModifierClass('teaser', isHomepageHero)
          )}
          style={{ color: teaserColor }}
        >
          {teaser.teaser}
        </p>
        {heroButton && (
          <ButtonGroup>
            <ButtonLink
              type="primary"
              href={heroButton.url}
              backgroundColor={heroButton.backgroundColor}
              color={heroButton.textColor}
            >
              {heroButton.text}
            </ButtonLink>
          </ButtonGroup>
        )}
      </div>
      <div
        className={classnames(
          heroStyles.imageWrapper,
          getElementAlignmentModifierClass('imageWrapper', isHomepageHero)
        )}
      >
        <img
          className={classnames(
            heroStyles.image,
            getElementAlignmentModifierClass('image', isHomepageHero)
          )}
          src={heroImage.file.url}
          alt={heroImageAltText}
        />
      </div>
    </div>
  </Section>
);

Hero.propTypes = {
  node: heroPropType.isRequired,
};

export default Hero;
